import React from 'react';
import { TiCompass,TiMap,TiWeatherSunny} from "react-icons/ti";
import Logo from '../../assets/logo.svg';
import './Presentation.css';

const Presentation = () => (
        <div className="container _presentation text-center">
            <div className="row justify-content-center">
                <div className="col-md-3 col-12">
                   <TiCompass className="_icon" />
                   <h3>Descubre</h3>
                   <p>Descubre decenas de ofertas exclusivas disponibles para los Quintanarroenses en Puerto Morelos.</p>
                </div>
                <div className="col-md-3 col-12">
                   <TiMap className="_icon" />
                  <h3>Visita</h3>
                  <p>Conecta y visita los lugares de tu interés, Recibirás grandes descuentos y promociones.</p>
                </div>
                <div className="col-md-3 col-12">
                   <TiWeatherSunny className="_icon" />
                  <h3>¡Disfruta!</h3>
                  <p>Explora con tu familia las bellezas naturales de la región, o visita con tu pareja alguno de los mejores restaurantes de la zona.</p>
                </div>
                <div className="col-md-3 col-12">
                   <img src={Logo} alt="p-morelos"/>
                </div>
            </div>
        </div>
    );

export default Presentation;