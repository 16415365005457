import React from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom'
import Layout from './Layout';
import Home from '../pages/Home/Home';
import Category from '../pages/Category/Category';
import Faqs from '../pages/Faqs/Faqs';
import Contact from '../pages/Contact/Contact';

const App = () =>(
        <BrowserRouter>
         <Layout>
             <Switch>
                 <Route exact path="/" component={Home} />
                 <Route exact path="/faqs" component={Faqs} />
                 <Route exact path="/contacto" component={Contact} />
                 <Route  path="/:name/:id" component={Category} />
                 
             </Switch>
         </Layout>
        </BrowserRouter>
    );

export default App;