import React from 'react';
import {Carousel} from 'react-bootstrap';
import './Banner.css';

const Banner = ({offers}) => (
    <Carousel className="_Banner" controls={false}>
        {(offers.length > 0) 
            ? offers.map(data=>{
                return <Carousel.Item key={data.id}>
                            <img
                                className="d-block w-100"
                                src="https://picsum.photos/id/322/1500/600"
                                alt="First slide"
                            />
                            <div className="_line_banner"  style={{background: data.category.color}}>
                                
                            </div>
                        </Carousel.Item>
            })
            : <Carousel.Item >
                    <img
                        className="d-block w-100"
                        src="https://picsum.photos/id/322/1500/600"
                        alt="First slide"
                    />
                    <div className="_line_banner"  style={{background: '#00BBE6'}}>
                        
                    </div>
                </Carousel.Item>
            }
        
    </Carousel>
    );

export default Banner;