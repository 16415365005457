import React from 'react';
import {Accordion,Card, Button} from 'react-bootstrap';
import { IoIosAdd } from "react-icons/io";
import './Collapse.css';

const Collapse = ({question, response}) => (
    <div className="col-12 mt-3 _card_response">
        <Accordion>
            <Card>
            <Card.Header>
                <div className="row _header-question">
                    <div className="col-10">
                        <h5 className="text-left">{question}</h5>
                    </div>
                    <div className="col-2">
                    <Accordion.Toggle className="btb-collap" as={Button} variant="link" eventKey="0">
                       <span><IoIosAdd /></span>
                    </Accordion.Toggle>
                    </div>
                </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>{response}</Card.Body>
            </Accordion.Collapse>
            </Card>
      </Accordion>
      </div>
    );

export default Collapse;