import React, { Component } from 'react';
import './Search.css';
import { FaSearch } from "react-icons/fa";

class Search extends Component {
     constructor(props){
         super(props);
         this.state={
             tag: ''
         }
     }

     handleChange=(e)=>{
       this.setState({tag: e.target.value});
     }

     handleSubmit=(e)=>{
        e.preventDefault();
        // this.props.history.push('/contacto')
        window.location.href = "/search";
     }

    render() {
        let {tag} = this.state;
        return (
            <div className="_Search">
                <div className="_cont_input">
                <div className="container">
                    <div className="row">
                        <div className="col-12 _font">
                            <h3>Vive la experiencia de Puerto Morelos</h3>
                            <h1>Conoce todas las ofertas disponibles para tí!</h1>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <div className="input_search">
                              <input 
                               value={tag}
                               onChange={this.handleChange}
                               required
                               placeholder="Busca descuentos y promociones..."
                               type="search" />
                              <button type="submit"><FaSearch /></button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
                </div>          
                <svg id="wave"  version="1.1" viewBox="0 0 2000.3 112.3" xmlns="http://www.w3.org/2000/svg">
                <path d="m2000.2 0-2000.2 0.48356 0.33422 26.32s307.12 85.496 512.61 85.496 511.47-89.995 919.45-89.995 567.93 68.996 567.93 68.996z" />
                </svg>
            </div>
        );
    }
}

export default Search;