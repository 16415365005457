import React, { Component } from 'react';
import axios from 'axios';
import URL from '../../config';
import './Form.css';

class Form extends Component {
    constructor(props){
        super(props);
        this.state={
            name: "",
            email: "",
            corp: "",
            phone: "",
            city: "",
            send: false,
            validation: false
        }
    }

    handleChange=(e)=>{
     this.setState({
         [e.target.name]: e.target.value
     })
    }

    handleSubmit= async (e)=>{
        e.preventDefault();
        if(this.state.name === '' && this.state.email === ''){
            this.setState({validation:true});
            setTimeout(()=> this.setState({validation:false}),1000);
        }else{
            const res = await axios.post(`${URL}/api/contact`,this.state);
            if(res.data.success){
                this.setState({name:'',email:'',corp:'',phone:'',city:'', send: true});
                setTimeout(()=> this.setState({send:false}),5000);
            }
        }
    }


    render() {
        let {name,email,corp,phone,city, send,validation} = this.state;
        return (
            <div className="_Form_contact">
                <h3>Si te interesa más información o aparecer en el directorio, escríbenos.</h3>
                <p>Nos comunicaremos contigo para resolver cualquier duda y opciones que se adapten a los requerimientos de tu negocio.</p>

                <form onSubmit={this.handleSubmit}>
                <div className="_message_success">
                    {(send) ? <p>Tus datos han sido enviados!</p> :''}
                </div>
                       <input
                       name="name"
                       onChange={this.handleChange}
                       required
                       maxLength="150"
                       value={name} 
                       type="text"
                       placeholder="Ingresa tu nombre *"/>
                       <input 
                       name="email"
                       onChange={this.handleChange}
                       required
                       maxLength="200"
                       value={email}
                       type="text" 
                       placeholder="Ingresa tu email *"/>
                       <input 
                       name="corp"
                       onChange={this.handleChange}
                       value={corp}
                       type="text" 
                       maxLength="200"
                       placeholder="Empresa"/>
                       <div className="double_input">
                          <div>
                              <input 
                              name="phone"
                              onChange={this.handleChange}
                              value={phone}
                              type="text" 
                              maxLength="100"
                              placeholder="Teléfono"/>
                           </div>
                          <div>
                              <input 
                              name="city"
                              onChange={this.handleChange}
                              value={city}
                              type="text" 
                              maxLength="200"
                              placeholder="Ciudad"/>
                          </div>
                       </div>
                   <p className={(validation) ? '_warning' : ''}>Los elementos marcados con <strong>*</strong> son información escencial.</p>
                   <button type="submit">ENVIAR MENSAJE</button>
                </form>
            </div>
        );
    }
}

export default Form;