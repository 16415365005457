import React from 'react';
import { FaPhone,FaMapMarkerAlt,FaEnvelope } from "react-icons/fa";
import './Card.css';

const Card = ({data}) =>(
        <div className={`mt-4 ${data.membership.class}`}>
            <div className="_Card" style={{background: data.theme.background}}>
                     <div className="row _header_info">
                         <div className="col-md-12 col-5 text-md-center text-right">
                            <img src={data.logo} width="80" alt=""/>
                         </div>
                         <div className="col-md-12 col-7 text-center">
                             <div className="h4 mt-2">
                                 <h4 style={{color: data.theme.font}}>{data.name}</h4></div>
                         </div>
                     </div>
                     <div className="info_contact">
                         <ul >
                             <li><FaPhone /><a style={{color: data.theme.font}} href="tel:6547867890">654 786 78 90</a></li>
                             <li> <FaEnvelope /><a style={{color: data.theme.font}} href="mailto:contact@aquanauts.com">contact@aquanauts.com</a></li>
                             <li><FaMapMarkerAlt/> 
                             <a style={{color: data.theme.font}} href="https://www.google.com.mx/maps/place/Plaza+de+Armas/@19.7033856,-101.1962703,16z/data=!4m13!1m7!3m6!1s0x842d0e71bef48e3f:0x770ef0040a020470!2sCentro+hist%C3%B3rico+de+Morelia,+58000+Morelia,+Mich.!3b1!8m2!3d19.7020748!4d-101.192885!3m4!1s0x842d0e70f5fc1ea7:0x30804f7796d2acf7!8m2!3d19.7023723!4d-101.1936897" rel="noopener noreferrer" target="_blank">Rafael E. Melgar, Zona Hotelera Nte, 77580</a>
                             </li>
                         </ul>
                     </div>
                 <div className="chips">
                    <div style={{background: data.category.color }}>{data.category.title}</div>
                    {data.label.map(data=>{
                        return <div key={data.id}>{data.title}</div>
                    })}
                 </div>           
            </div>
        </div>
    );

export default Card;