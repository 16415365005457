import React, { Component } from 'react';
import {Nav,Navbar,NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import LogoIdd from '../../assets/iddlogo.svg';
import URL from '../../config';
import axios from 'axios';
import './Header.css';

class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            categories: []
        }
    }
 
    componentDidMount(){
        this.getCategories();
    }
 
    getCategories= async ()=>{
        const categories = await axios.get(`${URL}/api/categories`);
        this.setState({categories: categories.data.data});
    }

    render() {
        let {categories} = this.state;
        return (
            <div  className="_menu_header fixed-top">
                <Navbar bg="light" className="container"  expand="lg">
                  
                        <Link to="/" className="navbar-brand _img_header">
                           <img src={LogoIdd}  alt=""/>
                        </Link>
                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                        <NavDropdown title="CATEGORÍAS" id="basic-nav-dropdown">
                            {categories.map((data,i)=>{
                                return <Link key={i} to={`/${data.title}/${data.id}`} className="dropdown-item">{data.title}</Link>
                                
                            })}     
                        </NavDropdown>
                        <Link to="/faqs" className="nav-link">FAQS</Link>
                        <Link to="/contacto" className="nav-link">CONTACTO</Link>
                        </Nav>
                        <div className="_btn_reg">
                           <Link to="/contacto">REGÍSTRATE</Link>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Header;