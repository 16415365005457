import React from 'react';
import {Link} from 'react-router-dom';
import './CardCategory.css';

const CardCategory = ({data}) =>(
        <div className="col-xl-2 col-lg-4 col-md-4 col-6 mt-3 tag_category">
            <Link to={`/${data.title}/${data.id}`} >
            <div className="_card_category" style={{borderColor: data.color}}>
            <h5>{data.title}</h5>
            </div>
            </Link>
        </div>
    );

export default CardCategory;