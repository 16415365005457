import React, { Component,Fragment } from 'react';
import axios from 'axios';
import Banner from '../../components/Banner/Banner';
import Search from '../../components/Search/Search';
import Presentation from '../../components/Presentation/Presentation';
import Categories from '../../components/Categories/Categories';
import Offers from '../../components/Offers/Offers';
import Footer from '../../components/Footer/Footer';
import Subscription from '../../components/Subscription/Subscription';
import URL from '../../config';

class Home extends Component {
    constructor(props){
        super(props);
        this.state={
            offers: [],
            titleOffers: ''
        }
    }

    componentDidMount(){
       window.scrollTo(0,0);
      this.getOffers();
    }

    getOffers = async ()=>{
        const offers = await axios.get(`${URL}/api/brands`);
        this.setState({offers: offers.data.data});
    }

    render() {
        let {offers, titleOffers} = this.state;
        return (
           <Fragment>
               <Banner offers={offers} title={titleOffers} />
               <Search />
               <Presentation />
               <Categories position="translateY(45%)" />
               <Offers offers={offers} />
               <Subscription />
               <Footer />
           </Fragment>
        );
    }
}

export default Home;