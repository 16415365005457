import React from 'react';
import Card from '../Card/Card';
import CardGold from '../CardGold/CardGold';
import {Link} from 'react-router-dom';
import './Offers.css';
import { IoIosAdd } from "react-icons/io";

const Offers = ({offers,title}) =>(
        <div className="_Offers">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        {(title === undefined || title === '') 
                        ? <h1>Ofertas Destacadas</h1>
                        : <h1>{title}</h1>}
                      
                      
                    </div>
                </div>
                <div className="row">
                    {offers.map(data =>{
                        if(data.membership.title === 'oro'){
                           return <CardGold key={data.id} data={data} />
                        }else{
                            return <Card key={data.id} data={data} />
                        }
                    })}
                    
                    <div className="col-md-3 mt-4 _card_new">
                        <Link to="/contacto">
                        <div className="_card_register text-center">
                                   <div className="__icon">
                                      <IoIosAdd />
                                   </div>
                                   <p className="mt-4">¿Tienes un negocio en <strong>Puerto Morelos</strong> y te gustaría aparecer aquí?</p>
                                   <h3><strong>Contáctanos</strong></h3>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

export default Offers;