import React, { Component } from 'react';
import './Contact.css';
import IdLogo from '../../assets/idlogo.svg';
import { FaInstagram,FaTwitter,FaFacebookF,FaLinkedinIn } from "react-icons/fa";
import Form from '../../components/Form/Form';

class Contact extends Component {
    

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div className="_Contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 order-md-1 order-2 text-md-left text-center mb-5">
                            <div className="contact_text">
                                <h1>Contacto</h1>
                                <img src={IdLogo} alt=""/>
                                <ul>
                                    <li><a href="iddigital.com.mx">iddigital.com.mx</a></li>
                                    <li><a href="mailto:info@iddigital.com.mx">info@iddigital.com.mx</a></li>
                                    <li>
                                        <a href="#!"><div><FaInstagram /></div></a>
                                        <a href="#!"><div><FaTwitter /></div></a>
                                        <a href="#!"><div><FaFacebookF /></div></a>
                                        <a href="#!"><div><FaLinkedinIn /></div></a>
                                    </li>
                                </ul>
                                <p>Marketing <br/>
                                <span>Jorge Bueno</span><br/>
                                <i>Director Comercial</i>
                                </p>
                                
                                <div className="c_jor">
                                    <a href="tel:+527772307537"> 777 230 75 37</a><br/>
                                    <a href="mailto:jbueno@iddigital.com.mx">jbueno@iddigital.com.mx</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 order-md-2 order-1 text-md-left text-center">
                              <Form />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;