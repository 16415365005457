import React from 'react';
import './Footer.css';
import IdLogo from '../../assets/idlogo.svg';
import PmLogo from '../../assets/logo.svg';
import { FaInstagram,FaTwitter,FaFacebookF,FaLinkedinIn } from "react-icons/fa";
import axios from 'axios';
import {Link} from 'react-router-dom';
import URL from '../../config';

class Footern extends React.Component{
   constructor(props){
       super(props);
       this.state={
           categories: []
       }
   }

   componentDidMount(){
       this.getCategories();
   }

   getCategories= async ()=>{
       const categories = await axios.get(`${URL}/api/categories`);
       this.setState({categories: categories.data.data});
   }

  render(){
      let {categories} = this.state;
      return (
        <footer className="_Footer">
        <svg id="wave_f" version="1.1" viewBox="0 0 2000.1 62.366" xmlns="http://www.w3.org/2000/svg">
        <path d="m0.083556 20.963-0.083556 41.403h1999.9l0.1545-29.403s-306-32.963-388-32.963-514 58.963-986 58.963c-472 0-626-38-626-38z" />
        </svg>
       <svg id="wave_f_m"  version="1.1" viewBox="0 0 125.88 19.986" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-70.025 -122.99)">
        <path d="m70.025 130.21c11.148-3.1458 24.844-6.6421 39.556-6.9372 28.042-1.6107 58.727 3.7814 86.328 10.958v8.7418h-125.88z" />
        </g>
        </svg> 
        <div className="content-footer">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 mt-3 order-md-1 order-2">
              <img src={IdLogo} alt=""/>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mt-3 order-md-2 order-3">
                     <div>
                     <h4 className="d-md-block d-none" >Contacto</h4>
                     <ul>
                         <li className="d-md-block d-none" ><a href="mailto:iddigital.com.mx">iddigital.com.mx</a></li>
                         <li className="d-md-block d-none" ><a href="mailto:info@iddigital.com.mx">info@iddigital.com.mx</a></li>
                         <li className="social_net text-md-left text-center">
                             <a href="#!"><div><FaInstagram /></div></a>
                             <a href="#!"><div><FaTwitter /></div></a>
                             <a href="#!"><div><FaFacebookF /></div></a>
                             <a href="#!"><div><FaLinkedinIn /></div></a>
                         </li>
                     </ul>
                     </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mt-3 order-md-3 order-4">
                    <div className="d-md-block d-none">
                    <h4>Categorías</h4>
                    <ul>
                        {categories.map((data,i)=>{
                            return <li key={i}><Link to={`/${data.title}/${data.id}`}>{data.title}</Link></li>
                        })}
                    </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mt-3 order-md-4 order-1">
                    <img src={PmLogo} width="180" alt=""/>
                </div>
            </div>
            <div className="row justify-content-end mt-4 _info_p">
                <div className="col-md-2 col-6 text-center">
                        <a href="#!">Terminos y condiciones</a>
                </div>
                <div className="col-md-2 col-6 text-center">
                        <a href="#!">Aviso de privacidad</a>
                </div>
            </div>
        </div>
        </div>
    </footer>
      )
  }
}



export default Footern;