import React, { Component } from 'react';
import CardCategory from '../CardCategory/CardCategory';
import axios from 'axios';
import './Categories.css';
import URL from '../../config';

class Categories extends Component {
    constructor(props){
        super(props);
        this.state={
            categories: []
        }
    }
    
    componentDidMount(){
      this.getCategories();
    }

    getCategories = async () =>{
       const categories = await axios.get(`${URL}/api/categories`);
       this.setState({categories: categories.data.data});
    }

    render() {
        let {position} = this.props;
        let {categories} = this.state;
        return (
            <div className="_categories" style={{transform: position}}>
                <div className="container">
                    <div className="row">
                        
                            {categories.map((data,i)=>{
                                return <CardCategory key={i} data={data} />
                            })}
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Categories;