import React, { Component,Fragment } from 'react';
import Banner from '../../components/Banner/Banner';
import Offers from '../../components/Offers/Offers';
import Categories from '../../components/Categories/Categories';
import Footer from '../../components/Footer/Footer';
import Subscription from '../../components/Subscription/Subscription';
import axios from 'axios';
import URL from '../../config';

class Category extends Component {
  constructor(props){
      super(props);
      this.state={
          offers :[],
          titleOffers: ''
      }
      
  }

  componentDidMount(){
    window.scrollTo(0,0);
    let {id} = this.props.match.params;
      this.getOffers(id);
      this.getCategory(id);
  }

   componentDidUpdate(prev_props){
      if(prev_props !== this.props){
          this.getOffers(this.props.match.params.id);
          this.getCategory(this.props.match.params.id);
      }
  }

  getOffers= async (id)=>{
      const offers = await axios.get(`${URL}/api/brands/${id}`);
      this.setState({offers: offers.data.data});
  }
  
  getCategory = async (id) =>{
     const category = await axios.get(`${URL}/api/categories/${id}`);
     this.setState({titleOffers: category.data.data.title});
  }

    render() {
        let {offers,titleOffers} = this.state;
        return (
            <Fragment>
               <Banner offers={offers} />
               <Offers offers={offers}  title={titleOffers}/>
               <Categories position="translateY(-50%)" />
               <Subscription />
               <Footer />
            </Fragment>
        );
    }
}

export default Category;