import React, { Component,Fragment } from 'react';

import Collapse from '../../components/Collapse/Collapse';
import './Faqs.css';
import Categories from '../../components/Categories/Categories';
import Footer from '../../components/Footer/Footer';
import Subscription from '../../components/Subscription/Subscription';
import Questions from '../../components/DataFaqs/Questions.json';

class Faqs extends Component {


    constructor(props){
        super(props);
        this.state={
            generales: [],
            patrocinadores: []
        }
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.getQuestions();
    }

    getQuestions=()=>{
        this.setState({generales: Questions.generales, patrocinadores: Questions.patrocinadores})
    }
    render() {
        let {generales, patrocinadores} = this.state;
        
        return (
            <Fragment>
            <div className="_Faq">
               <div className="container _tit">
                   <div className="row">
                       <div className="col-12">
                           <h1>FAQ</h1>
                           <h3>Preguntas frecuentes</h3>
                       </div>
                   </div>
               </div>
               <div className="_questions">
                 <div className="container">
                     <div className="row">
                         <div className="col-md-6 mt-4">
                             <h2>Preguntas Generales</h2>
                            <div className="row">
                                {generales.map((data,i)=>{
                                    return <Collapse key={i} question={data.pregunta} response={data.respuesta} />
                                })}
                            </div>
                         </div>
                         <div className="col-md-6 mt-4">
                             <h2>Preguntas de patrocinadores</h2>
                            <div className="row">
                                {patrocinadores.map((data,i)=>{
                                    return <Collapse key={i} question={data.pregunta} response={data.respuesta} />
                                })}
                            </div>
                         </div>
                     </div>
                 </div>
               </div>
            </div>
            <Categories position="translateY(-50%)" />
               <Subscription />
               <Footer />
            </Fragment>
        );
    }
}

export default Faqs;