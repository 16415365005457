import React, { Component } from 'react';
import { FaAngleRight } from "react-icons/fa";
import './Subscription.css';


class Subscription extends Component {
    render() {
        return (
            <div className="_subscription">
                <div className="container">
                  <div className="row">
                      <div className="col-12 _font">
                          <h1>Suscríbete a nuestro newsletter</h1>
                          <h3>Para mantenerte informado de nuevas promociones.</h3>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <form >
                              <div className="_input_sub">
                                 <input type="text"/>
                                 <button type="submit"><FaAngleRight /></button>
                              </div>
                          </form>
                      </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default Subscription;